export const FirebaseConfig = {
	"projectId": "motolink-taxi-st",
	"appId": "1:767223580380:web:1b6695b6950a6432d3d619",
	"databaseURL": "https://motolink-taxi-st.firebaseio.com",
	"storageBucket": "motolink-taxi-st.appspot.com",
	"locationId": "europe-west2",
	"apiKey": "AIzaSyCpMrIfhXLCnd5kdVZQqIr4H-ryuK8REIE",
	"authDomain": "motolink-taxi-st.firebaseapp.com",
	"messagingSenderId": "767223580380",
	"measurementId": "G-JGZWTXJ5HW"
};